import {
  Center,
  CircularProgress,
  Flex,
  HStack,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFirebase } from "../../context/firebase.context";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import canonicalDate from "../../libs/canonical-date";
import numeral from "numeral";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

const INCOME_COLORS = [
  "#c6ff00",
  "#7cb342",
  "#4caf50",
  "#2196f3",
  "#3949ab",
  "#512da8",
  "#e91e63",
  "#c62828",
];

const typeNameMapping = {
  CHATROOM_MESSAGE: "聊天訊息",
  CHATROOM_GIFT: "聊天禮物",
  CHATROOM_UNLOCK_VIDEO: "聊天影片",
  CHATROOM_UNLOCK_IMAGE: "聊天圖片",
  UNLOCK_VIDEO: "解鎖影片",
};

const CreatorWallet = ({ creator }) => {
  const { firestore } = useFirebase();
  const [transactions, setTransactions] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [daysRange, setDaysRange] = useState(7);
  const [isLoading, setIsLoading] = useState(false);

  const loadTransactions = useCallback(async () => {
    if (!creator) return;

    setIsLoading(true);
    try {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - daysRange);
      startDate.setHours(0, 0, 0, 0);
      const startTimestamp = new Date(startDate);
      console.log("startTimestamp", startTimestamp);
      const q = query(
        collection(firestore, "transactions"),
        where("creatorId", "==", creator.id),
        where("createdAt", ">=", startTimestamp.valueOf()),
        orderBy("createdAt", "desc"),
      );

      const querySnapshot = await getDocs(q);
      const txs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Calculate analytics
      const txAnalytics = txs.reduce((acc, cur) => {
        const { type, points } = cur;
        if (acc[type]) {
          acc[type].count += 1;
          acc[type].total += points;
        } else {
          acc[type] = { count: 1, total: points };
        }
        return acc;
      }, {});

      setTransactions(txs);
      setAnalytics(txAnalytics);
    } catch (error) {
      console.error("Error loading transactions:", error);
    } finally {
      setIsLoading(false);
    }
  }, [creator, daysRange, firestore]);

  const totalIncome = useMemo(() => {
    if (!analytics) return null;
    return Object.values(analytics).reduce((acc, cur) => acc + cur.total, 0);
  }, [analytics]);

  const data = useMemo(() => {
    if (!analytics) return null;

    return Object.entries(analytics).map(([key, d], index) => ({
      type: key,
      value: d.total,
      count: d.count,
      color: INCOME_COLORS[index % 8],
    }));
  }, [analytics]);

  useEffect(() => {
    loadTransactions();
  }, [loadTransactions, daysRange]);

  return (
    <VStack align="stretch">
      <HStack spacing={4} p={4}>
        <Text>顯示最近：</Text>
        <Select
          value={daysRange}
          onChange={(e) => setDaysRange(Number(e.target.value))}
          width="200px"
        >
          <option value={7}>7天</option>
          <option value={30}>30天</option>
          <option value={90}>90天</option>
          <option value={180}>180天</option>
          <option value={365}>365天</option>
        </Select>
      </HStack>
      {(transactions === null || isLoading) && (
        <Center mt={6}>
          <CircularProgress isIndeterminate />
        </Center>
      )}
      {data && (
        <VStack align="center">
          <Text align="center">總鑽石收入：{totalIncome}</Text>
          <ResponsiveContainer width="100%" height={360}>
            <PieChart>
              <Pie
                label={({ index }) => {
                  const { type, value } = data[index];
                  const number = numeral(value).format("0.00a");
                  return `${typeNameMapping[type]} ${number}(${((value / totalIncome) * 100).toFixed(1)}%)`;
                }}
                data={data}
                cx="50%"
                cy={180}
                startAngle={90}
                endAngle={450}
                innerRadius={55}
                outerRadius={70}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={data[index].color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </VStack>
      )}
      {transactions?.map(({ id, type, createdAt, points }) => (
        <Flex key={id} p={3} borderBottomWidth={1} gap={3} align="center">
          <Text>{points}</Text>
          <Text flex={1}>{type}</Text>
          <Text>{canonicalDate(createdAt)}</Text>
        </Flex>
      ))}
    </VStack>
  );
};

export default CreatorWallet;
