import {
  Box,
  Button,
  Center,
  Circle,
  CircularProgress,
  Fade,
  Flex,
  Image,
  Spinner,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors,} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import MainLayout from "@layouts/main.layout";
import FAIcon from "components/FAIcon";
import VideoSelector from "components/VideoSelector";
import {useFirebase} from "context/firebase.context";
import {useCallback, useEffect, useState} from "react";
import {doc, onSnapshot, serverTimestamp, setDoc} from "firebase/firestore";
import useAPI from "../hooks/api";

const VideoItem = ({id, thumbnail, thumbnails, onRemove}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <Box
            ref={setNodeRef}
            style={style}
            position="relative"
            mb={3}
            bg={isDragging ? "gray.700" : "transparent"}
            p={2}
            borderRadius="md"
            {...attributes}
        >
            <Flex align="center" gap={4} {...attributes}>
                <Center cursor="grab" px={2} {...listeners}>
                    <FAIcon name="grip-vertical"/>
                </Center>
                <Box>
                    <Flex align="center" position="relative">
                        <Image
                            src={thumbnail || thumbnails}
                            width="120px"
                            height="213px"
                            objectFit="cover"
                            borderRadius="md"
                            cursor="grab"
                            {...listeners}
                        />
                        <Circle
                            size={8}
                            bg="red.400"
                            role="button"
                            onClick={onRemove}
                            cursor="pointer"
                            position="absolute"
                            right="-12px"
                            top="0"
                        >
                            <FAIcon name="close"/>
                        </Circle>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};

export default function ShortVideosPage() {
    const api = useAPI();

    const [isDesktop] = useMediaQuery("(min-width: 992px)");
    const {firestore} = useFirebase();
    const [videos, setVideos] = useState([]);
    const [updateStatus, setUpdateStatus] = useState("IDLE");
    const [isSelecting, setIsSelecting] = useState(false);
    const [shortVideosRef] = useState(() =>
        doc(firestore, "app", "short-videos"),
    );
    const [videoIds, setVideoIds] = useState([]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    useEffect(() => {
        const loadVideoDetails = async (ids) => {
            try {
                const videoPromises = ids.map(async (id) => {
                    const response = await api.getVideo(id);
                    if (response && !response.error) {
                        return {
                            id,
                            thumbnail: response.thumbnail,
                            thumbnails: response.thumbnails,
                        };
                    }
                    return null;
                });

                const videoDetails = await Promise.all(videoPromises);
                setVideos(videoDetails.filter((v) => v !== null));
            } catch (error) {
                console.error("Error loading video details:", error);
                setVideos([]);
            }
        };

        const unsubscribe = onSnapshot(shortVideosRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.data();
                const ids = data.videos || [];
                setVideoIds(ids);
                loadVideoDetails(ids);
            } else {
                setDoc(shortVideosRef, {videos: []});
                setVideoIds([]);
                setVideos([]);
            }
        });

        return () => unsubscribe();
    }, [firestore, api, shortVideosRef]);

    const addVideo = useCallback(
        async (video) => {
            try {
                setUpdateStatus("UPDATING");
                setIsSelecting(false);

                const updatedVideoIds = [video.id, ...videoIds];
                await setDoc(shortVideosRef, {videos: updatedVideoIds, updatedAt: serverTimestamp()});

                // Firebase listener will handle the state update
                setUpdateStatus("DONE");
                setTimeout(() => setUpdateStatus("IDLE"), 1000);
            } catch (error) {
                console.error("Error adding video:", error);
                setUpdateStatus("IDLE");
            }
        },
        [shortVideosRef, videoIds],
    );

    const removeVideo = useCallback(
        async (videoId) => {
            try {
                setUpdateStatus("UPDATING");

                const updatedVideoIds = videoIds.filter((id) => id !== videoId);
                await setDoc(shortVideosRef, {videos: updatedVideoIds, updatedAt: serverTimestamp()});

                // Firebase listener will handle the state update
                setUpdateStatus("DONE");
                setTimeout(() => setUpdateStatus("IDLE"), 1000);
            } catch (error) {
                console.error("Error removing video:", error);
                setUpdateStatus("IDLE");
            }
        },
        [shortVideosRef, videoIds],
    );

    return (
        <MainLayout p={3}>
            <Box maxW={1200} mx="auto">
                <Flex justify="space-between" align="center" mb={6}>
                    <Text fontSize="2xl">短影音推薦設定</Text>
                    <Button onClick={() => setIsSelecting(true)} colorScheme="blue">
                        新增影片
                    </Button>
                </Flex>

                {videos === null ? (
                    <Center mt={6}>
                        <CircularProgress isIndeterminate size={12}/>
                    </Center>
                ) : (
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={(event) => {
                            const {active, over} = event;
                            if (!over || active.id === over.id) return;

                            setUpdateStatus("UPDATING");
                            const oldIndex = videos.findIndex((v) => v.id === active.id);
                            const newIndex = videos.findIndex((v) => v.id === over.id);

                            const newVideos = arrayMove(videos, oldIndex, newIndex);
                            const videoIds = newVideos.map((v) => v.id);

                            setDoc(doc(firestore, "app", "short-videos"), {
                                videos: videoIds,
                                updatedAt: serverTimestamp(),
                            }).then(() => {
                                setVideos(newVideos);
                                setUpdateStatus("DONE");
                                setTimeout(() => setUpdateStatus("IDLE"), 1000);
                            });
                        }}
                    >
                        <SortableContext
                            items={videos.map((v) => v.id)}
                            strategy={verticalListSortingStrategy}
                        >
                            <VStack align="stretch" spacing={2} width="100%">
                                {videos.map((video, index) => (
                                    <VideoItem
                                        key={`video-${video.id}`}
                                        {...video}
                                        onRemove={() => removeVideo(video.id)}
                                    />
                                ))}
                            </VStack>
                        </SortableContext>
                    </DndContext>
                )}
            </Box>

            <Fade in={updateStatus !== "IDLE"} unmountOnExit>
                <Box position="fixed" bottom={0} right={0} p={4} bg="blackAlpha.500">
                    {updateStatus === "UPDATING" && (
                        <Flex align="center" gap={2} color="white">
                            更新中...
                            <Spinner/>
                        </Flex>
                    )}
                    {updateStatus === "DONE" && (
                        <Flex align="center" gap={2} color="white">
                            已同步
                            <FAIcon name="check"/>
                        </Flex>
                    )}
                </Box>
            </Fade>

            {isSelecting && (
                <Box
                    position="fixed"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    bg="blackAlpha.600"
                    zIndex={1000}
                >
                    <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        bg="gray.700"
                        borderRadius="md"
                        p={4}
                        width={isDesktop ? "600px" : "90vw"}
                        maxH="90vh"
                        overflow="auto"
                    >
                        <Flex justify="space-between" align="center" mb={4}>
                            <Text fontSize="xl" color="white">
                                選擇影片
                            </Text>
                            <FAIcon
                                name="close"
                                role="button"
                                onClick={() => setIsSelecting(false)}
                            />
                        </Flex>
                        <VideoSelector onSelect={addVideo}/>
                    </Box>
                </Box>
            )}
        </MainLayout>
    );
}
