import { Box, Center, CircularProgress, Flex, Text } from "@chakra-ui/react";
import { useFirebase } from "context/firebase.context";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { ChatsList } from "components/creator/ChatsList";

const UserChatHistory = ({ userId }) => {
  const { firestore } = useFirebase();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) return;

      setLoading(true);
      try {
        // Get the user data
        const userQuery = query(
          collection(firestore, "users"),
          where("__name__", "==", userId),
        );
        const userSnapshot = await getDocs(userQuery);
        if (!userSnapshot.empty) {
          setUserData({
            id: userSnapshot.docs[0].id,
            ...userSnapshot.docs[0].data(),
          });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId, firestore]);

  if (loading) {
    return (
      <Center py={10}>
        <CircularProgress isIndeterminate />
      </Center>
    );
  }

  if (!userData) {
    return (
      <Center py={10}>
        <Text color="gray.500">無法載入用戶資料</Text>
      </Center>
    );
  }

  return (
    <Box>
      <Text mb={4} fontWeight="bold">
        用戶聊天記錄
      </Text>
      <Flex
        height={{ base: "60vh", md: "calc(100vh - 650px)" }}
        maxHeight={{ base: "60vh", md: "calc(100vh - 650px)" }}
        width="100%"
        overflow="auto"
      >
        <ChatsList creator={userData} userId={userId} />
      </Flex>
    </Box>
  );
};

export default UserChatHistory;
