import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";
import { useCallback, useState } from "react";
import { useFirebase } from "context/firebase.context";
import MainLayout from "@layouts/main.layout";
import ChatList from "components/creator/chat/ChatList";
import ChatDetail from "components/creator/chat/ChatDetail";

export default function ChatsManagementPage() {
  const { firestore } = useFirebase();
  const [searchTerm] = useState("");
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedChatData, setSelectedChatData] = useState(null);
  const [messages, setMessages] = useState(null);
  const [allChats, setAllChats] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Handle chat selection
  const handleSelectChat = useCallback(
    (chatId, chatData) => {
      // Reset state when selecting a new chat
      setSelectedChat(chatId);
      setSelectedChatData(chatData);
      setMessages(null);

      // Open the drawer on mobile when a chat is selected
      if (isMobile) {
        onOpen();
      }
    },
    [isMobile, onOpen],
  );

  // Process chats to add creator display names
  const processChats = useCallback(
    async (chats) => {
      if (!chats || chats.length === 0) return [];

      const processedChats = await Promise.all(
        chats.map(async (chat) => {
          if (chat.creatorId) {
            try {
              const creatorDoc = await getDoc(
                doc(firestore, "creators", chat.creatorId),
              );
              if (creatorDoc.exists()) {
                return {
                  ...chat,
                  creatorDisplayName:
                    creatorDoc.data().displayName || "未命名創作者",
                };
              }
            } catch (error) {
              console.error("Error fetching creator:", error);
            }
          }
          return chat;
        }),
      );

      setAllChats(processedChats);
      return processedChats;
    },
    [firestore],
  );

  // Filter chats based on search term
  const filteredChats =
    searchTerm && allChats.length > 0
      ? allChats.filter(
          (chat) =>
            chat.displayName
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            chat.lastMessage?.text
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            chat.creatorDisplayName
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()),
        )
      : null;

  return (
    <MainLayout>
      <Flex maxHeight="calc(100vh - 64px)" overflow="hidden">
        <Flex flex={1}>
          {/* Chat list - always visible */}
          <Box
            width={isMobile ? "100%" : "350px"}
            borderRight={!isMobile ? "1px solid" : "none"}
            borderColor="gray.700"
            pr={2}
          >
            <ChatList
              firestore={firestore}
              onSelectChat={handleSelectChat}
              selectedChatId={selectedChat}
              onChatsLoaded={processChats}
              filteredChats={filteredChats}
            />
          </Box>

          {/* Desktop Chat detail */}
          {!isMobile && (
            <Box
              flex={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {selectedChat ? (
                <ChatDetail
                  key={selectedChat}
                  chatId={selectedChat}
                  creator={selectedChatData?.creator}
                  firestore={firestore}
                  messages={messages}
                  setMessages={setMessages}
                />
              ) : (
                <Text color="gray.500">選擇一個聊天室來查看對話</Text>
              )}
            </Box>
          )}

          {/* Mobile Chat detail drawer */}
          {isMobile && (
            <Drawer
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
              size="full"
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">聊天內容</DrawerHeader>
                <DrawerBody p={0}>
                  {selectedChat ? (
                    <ChatDetail
                      key={selectedChat}
                      chatId={selectedChat}
                      creator={selectedChatData?.creator}
                      firestore={firestore}
                      messages={messages}
                      setMessages={setMessages}
                    />
                  ) : (
                    <Flex height="100%" align="center" justify="center">
                      <Text color="gray.500">選擇一個聊天室來查看對話</Text>
                    </Flex>
                  )}
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          )}
        </Flex>
      </Flex>
    </MainLayout>
  );
}
