import {
  Badge,
  Box,
  Button,
  Collapse,
  Flex,
  IconButton,
  Input,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import FAIcon from "../../FAIcon";
import useAPI from "../../../hooks/api";

const MessageInput = ({ onSendMessage, chatId }) => {
  const api = useAPI();
  const [message, setMessage] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  const handleSendMessage = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setMessage(suggestion);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const fetchSuggestions = () => {
    if (chatId) {
      setIsLoading(true);
      // Call API to get suggestions
      api
        .apiRequest(`/chatrooms/suggestions/${chatId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => response.data)
        .then((data) => {
          console.log("API Response:", data);
          setApiResponse(data);
        })
        .catch((error) => {
          console.error("API Error:", error);
          setApiResponse({ error: error.message });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Box position="relative" mt={2} mb={2}>
      {apiResponse && (
        <Box mb={3}>
          <Flex justifyContent="space-between" alignItems="center" mb={2}>
            <Flex alignItems="center">
              <Badge colorScheme="purple" mr={2}>
                AI 分析
              </Badge>
              <Text fontSize="xs" color="gray.400">
                {apiResponse.intentionCategory}
              </Text>
            </Flex>
            <IconButton
              icon={<FAIcon name={isOpen ? "chevron-up" : "chevron-down"} />}
              size="xs"
              variant="ghost"
              onClick={onToggle}
              aria-label={isOpen ? "收起" : "展開"}
            />
          </Flex>

          <Collapse in={isOpen} animateOpacity>
            <Box bg="gray.800" borderRadius="md" p={3} mb={3} fontSize="sm">
              <Flex wrap="wrap" gap={3}>
                <Box flex="1" minW="200px">
                  <Text fontWeight="bold" fontSize="xs" mb={1} color="blue.300">
                    分析摘要
                  </Text>
                  <Text fontSize="xs" color="gray.300">
                    {apiResponse.analysisSummary}
                  </Text>
                </Box>

                <Box flex="1" minW="200px">
                  <Text
                    fontWeight="bold"
                    fontSize="xs"
                    mb={1}
                    color="green.300"
                  >
                    建議回應方式
                  </Text>
                  <Text fontSize="xs" color="gray.300">
                    {apiResponse.recommendedApproach}
                  </Text>
                </Box>
              </Flex>
            </Box>

            <Box mb={3}>
              <Text fontWeight="bold" fontSize="xs" mb={2} color="orange.300">
                建議回覆
              </Text>
              <Flex gap={2} flexWrap="wrap">
                {apiResponse.suggestions?.map((suggestion, index) => (
                  <Tooltip
                    key={index}
                    label={suggestion}
                    placement="top"
                    openDelay={500}
                  >
                    <Badge
                      bg="whiteAlpha.200"
                      p={2}
                      borderRadius="md"
                      fontSize="xs"
                      cursor="pointer"
                      _hover={{ bg: "whiteAlpha.300" }}
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion.length > 30
                        ? `${suggestion.substring(0, 30)}...`
                        : suggestion}
                    </Badge>
                  </Tooltip>
                ))}
              </Flex>
            </Box>
          </Collapse>
        </Box>
      )}

      <Flex justifyContent="flex-end" mb={1}>
        <Button
          size="xs"
          colorScheme="blue"
          variant="outline"
          leftIcon={
            isLoading ? <Spinner size="xs" /> : <FAIcon name="lightbulb" />
          }
          onClick={fetchSuggestions}
          borderRadius="full"
          px={3}
          py={1}
          fontSize="xs"
          isLoading={isLoading}
          loadingText="讀取中"
        >
          取得聊天建議
        </Button>
      </Flex>

      <Box bg="gray.800" borderRadius="md" p={2}>
        <Input
          ref={inputRef}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="輸入訊息..."
          bg="gray.700"
          border="none"
          _focus={{ border: "none", boxShadow: "none" }}
        />
      </Box>
    </Box>
  );
};

export default MessageInput;
