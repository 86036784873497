import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  HStack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import getPublicDownloadUrl from "libs/get-public-download-url";
import { useFirebase } from "context/firebase.context";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import dayjs from "dayjs";
import MainLayout from "../../layouts/main.layout";

const EarningRow = React.memo(
  ({ id, totalPoints, pointsByDay, userData, scope, index }) => {
    const navigate = useNavigate();
    const name = userData?.displayName;

    const handleNameClick = () => {
      if (id) {
        if (scope === "CREATOR") {
          navigate(`/creators/${id}`);
        } else {
          navigate(`/users/search?id=${id}`);
        }
      }
    };
    const picture = userData?.picture;

    return (
      <Tr>
        <Td isNumeric>{scope === "CREATOR" ? index + 1 : index + 1}</Td>
        <Td>
          <HStack
            spacing={3}
            onClick={handleNameClick}
            cursor="pointer"
            _hover={{ opacity: 0.8 }}
          >
            <Avatar size="sm" src={getPublicDownloadUrl(picture)} />
            <Text>{name || "載入中..."}</Text>
          </HStack>
        </Td>
        <Td isNumeric>{pointsByDay.today || 0}</Td>
        <Td isNumeric>{pointsByDay.yesterday || 0}</Td>
        <Td isNumeric>{pointsByDay.twoDaysAgo || 0}</Td>
        <Td isNumeric>{totalPoints}</Td>
      </Tr>
    );
  },
);

export default function EarningsPage() {
  const [creatorEarnings, setCreatorEarnings] = useState([]);
  const [userEarnings, setUserEarnings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usersData, setUsersData] = useState({});
  const [creatorsData, setCreatorsData] = useState({});
  const { firestore } = useFirebase();
  const [sortConfig, setSortConfig] = useState({
    key: "totalPoints",
    direction: "descending",
  });

  const sortedEarnings = (earnings) => {
    if (sortConfig.key) {
      return [...earnings].sort((a, b) => {
        const aValue = Math.abs(
          sortConfig.key.split(".").reduce((o, i) => o[i], a),
        );
        const bValue = Math.abs(
          sortConfig.key.split(".").reduce((o, i) => o[i], b),
        );

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return earnings;
  };

  const requestSort = (key) => {
    console.log(key);
    let direction = "descending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return null;
  };
  useEffect(() => {
    const fetchUserAndCreatorData = async () => {
      if (!creatorEarnings.length && !userEarnings.length) return;

      const creatorIds = creatorEarnings.map((e) => e.id);
      const userIds = userEarnings.map((e) => e.id);

      if (creatorIds.length) {
        const creatorPromises = creatorIds.map((id) =>
          getDoc(doc(firestore, "creators", id)),
        );

        const creatorSnapshots = await Promise.all(creatorPromises);
        const creatorData = {};
        creatorSnapshots.forEach((docSnap) => {
          if (docSnap.exists()) {
            creatorData[docSnap.id] = { id: docSnap.id, ...docSnap.data() };
          }
        });
        setCreatorsData(creatorData);
      }

      if (userIds.length) {
        const userPromises = userIds.map((id) =>
          getDoc(doc(firestore, "users", id)),
        );

        const userSnapshots = await Promise.all(userPromises);
        const userData = {};
        userSnapshots.forEach((docSnap) => {
          if (docSnap.exists()) {
            userData[docSnap.id] = { id: docSnap.id, ...docSnap.data() };
          }
        });
        setUsersData(userData);
      }
    };

    fetchUserAndCreatorData();
  }, [firestore, creatorEarnings, userEarnings]);

  useEffect(() => {
    const threeDaysAgo = dayjs().subtract(7, "days").valueOf();

    const q = query(
      collection(firestore, "transactions"),
      where("createdAt", ">=", threeDaysAgo),
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const transactions = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Group by creatorId/userId and sum points
      const grouped = transactions.reduce((acc, transaction) => {
        const key =
          transaction.scope === "CREATOR"
            ? transaction.creatorId
            : transaction.userId;

        if (!key) return acc;

        if (!acc[key]) {
          acc[key] = {
            id: key,
            scope: transaction.scope,
            totalPoints: 0,
            pointsByDay: {
              today: 0,
              yesterday: 0,
              twoDaysAgo: 0,
            },
          };
        }

        const points = transaction.points || 0;
        acc[key].totalPoints += points;

        // Determine which day this transaction belongs to
        const today = dayjs().startOf("day");
        const transactionDate = dayjs(transaction.createdAt);

        if (transactionDate.isSame(today, "day")) {
          acc[key].pointsByDay.today += points;
        } else if (transactionDate.isSame(today.subtract(1, "day"), "day")) {
          acc[key].pointsByDay.yesterday += points;
        } else if (transactionDate.isSame(today.subtract(2, "day"), "day")) {
          acc[key].pointsByDay.twoDaysAgo += points;
        }

        return acc;
      }, {});

      const earnings = Object.values(grouped);

      // Split and sort by points
      const creators = earnings
        .filter((e) => e.scope === "CREATOR" && Math.abs(e.totalPoints) > 200)
        .sort((a, b) => b.totalPoints - a.totalPoints);

      const users = earnings
        .filter((e) => e.scope === "USER" && Math.abs(e.totalPoints) > 200)
        .sort((a, b) => Math.abs(b.totalPoints) - Math.abs(a.totalPoints));

      setCreatorEarnings(creators);
      setUserEarnings(users);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [firestore]);

  if (loading) {
    return (
      <MainLayout>
        <Box p={4}>
          <Text>載入中...</Text>
        </Box>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Box p={4}>
        <Tabs variant="enclosed">
          <TabList>
            <Tab>創作者收益查詢</Tab>
            <Tab>用戶花費／儲值查詢</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th width="80px">項次</Th>
                    <Th
                      width="200px"
                      onClick={() => requestSort("name")}
                      cursor="pointer"
                    >
                      創作者名稱 {getSortIcon("name")}
                    </Th>
                    <Th
                      isNumeric
                      onClick={() => requestSort("pointsByDay.today")}
                      cursor="pointer"
                    >
                      今天 {getSortIcon("pointsByDay.today")}
                    </Th>
                    <Th
                      isNumeric
                      onClick={() => requestSort("pointsByDay.yesterday")}
                      cursor="pointer"
                    >
                      昨天 {getSortIcon("pointsByDay.yesterday")}
                    </Th>
                    <Th
                      isNumeric
                      onClick={() => requestSort("pointsByDay.twoDaysAgo")}
                      cursor="pointer"
                    >
                      前天 {getSortIcon("pointsByDay.twoDaysAgo")}
                    </Th>
                    <Th
                      isNumeric
                      onClick={() => requestSort("totalPoints")}
                      cursor="pointer"
                    >
                      總點數 {getSortIcon("totalPoints")}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sortedEarnings(creatorEarnings).map((earning, index) => (
                    <EarningRow
                      key={earning.id}
                      {...earning}
                      userData={creatorsData[earning.id]}
                      scope="CREATOR"
                      index={index}
                    />
                  ))}
                </Tbody>
              </Table>
            </TabPanel>
            <TabPanel>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th width="80px">項次</Th>
                    <Th
                      width="200px"
                      onClick={() => requestSort("name")}
                      cursor="pointer"
                    >
                      用戶名稱 {getSortIcon("name")}
                    </Th>
                    <Th
                      isNumeric
                      onClick={() => requestSort("pointsByDay.today")}
                      cursor="pointer"
                    >
                      今天 {getSortIcon("pointsByDay.today")}
                    </Th>
                    <Th
                      isNumeric
                      onClick={() => requestSort("pointsByDay.yesterday")}
                      cursor="pointer"
                    >
                      昨天 {getSortIcon("pointsByDay.yesterday")}
                    </Th>
                    <Th
                      isNumeric
                      onClick={() => requestSort("pointsByDay.twoDaysAgo")}
                      cursor="pointer"
                    >
                      前天 {getSortIcon("pointsByDay.twoDaysAgo")}
                    </Th>
                    <Th
                      isNumeric
                      onClick={() => requestSort("totalPoints")}
                      cursor="pointer"
                    >
                      總點數 {getSortIcon("totalPoints")}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sortedEarnings(userEarnings).map((earning, index) => (
                    <EarningRow
                      key={earning.id}
                      {...earning}
                      userData={usersData[earning.id]}
                      scope="USER"
                      index={index}
                    />
                  ))}
                </Tbody>
              </Table>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </MainLayout>
  );
}
