import {
  Box,
  Button,
  Circle,
  CircularProgress,
  Collapse,
  Flex,
  IconButton,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { ChatsList } from "components/creator/ChatsList";
import MainLayout from "@layouts/main.layout";
import FAIcon from "@components/FAIcon";
import useAPI from "hooks/api";
import getPublicDownloadUrl from "libs/get-public-download-url";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useVideoCountByCreatorId } from "hooks/useVideoCountByCreatorId";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useFirebase } from "context/firebase.context";
import CreatorWallet from "../../components/creator/CreatorWallet";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { deleteCreator } from "../../libs/delete-creator";
import { VideosList } from "../../components/creator/VideosList";

const FollowersList = ({ id }) => {
  const api = useAPI();
  const [followers, setFollowers] = useState([]);

  const loadCreatorFollowers = useCallback(async () => {
    const result = await api.getCreatorFollowers(id);
    if (result) setFollowers(result.data);
  }, [api, id]);

  useEffect(() => {
    loadCreatorFollowers();
  }, [loadCreatorFollowers]);

  return (
    <SimpleGrid spacing={2}>
      {followers.map((follower) => {
        return (
          <Flex
            justifyContent={"space-between"}
            key={_.get(follower, "user.id")}
            alignItems={"center"}
          >
            <Flex alignItems={"center"} columnGap={4}>
              <Circle
                size={12}
                backgroundColor={"black"}
                backgroundImage={
                  _.get(follower, "user.picture")
                    ? getPublicDownloadUrl(_.get(follower, "user.picture"))
                    : null
                }
                backgroundPosition={"center"}
                backgroundSize={"cover"}
              ></Circle>
              <Box>
                <Text fontWeight={"semibold"}>
                  {_.get(follower, "user.displayName")}
                </Text>
                <Text color={"gray.400"} fontSize={"sm"}>
                  {dayjs
                    .unix(_.get(follower, "followedAt") / 1000)
                    .format("YYYY-MM-DD HH:mm:ss")}
                </Text>
              </Box>
            </Flex>
            <Flex columnGap={4} fontSize={"lg"}>
              <FAIcon cursor={"pointer"} group="solid" name={"comment"} />
              <FAIcon cursor={"pointer"} group="solid" name={"circle-info"} />
            </Flex>
          </Flex>
        );
      })}
    </SimpleGrid>
  );
};

export default function CreatorFetchPage() {
  const params = useParams();
  const { id } = params;
  const [creator, setCreator] = useState(null);
  const { firestore } = useFirebase();
  const navigate = useNavigate();
  const toast = useToast();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditingIntroduce, setIsEditingIntroduce] = useState(false);
  const [introduce, setIntroduce] = useState("");
  const [isEditingSlug, setIsEditingSlug] = useState(false);
  const [slug, setSlug] = useState("");
  const [isInfoExpanded, setIsInfoExpanded] = useState(true);
  const { count, loading } = useVideoCountByCreatorId(id);

  useEffect(() => {
    if (!id) return;

    const docRef = doc(firestore, "creators", id);
    const unsubscribe = onSnapshot(docRef, {
      next: (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setCreator({ id: docSnap.id, ...data });
          setIntroduce(data.introduce || "");
          setSlug(data.slug || "");
        }
      },
      includeMetadataChanges: true,
    });

    return () => unsubscribe();
  }, [id, firestore]);

  // when removing videos, reduce videos total by 1
  const reduceVideosTotal = useCallback(() => {
    const videosTotal = _.get(creator, "videosTotal", 0);
    setCreator({
      ...creator,
      videosTotal: videosTotal - 1,
    });
  }, [creator]);

  return (
    <MainLayout>
      <SimpleGrid
        paddingY={8}
        height="100%"
        display="flex"
        flexDirection="column"
        overflow={"hidden"}
        columns={1}
      >
        <Collapse in={isInfoExpanded} animateOpacity>
          <Flex
            rowGap={4}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            maxHeight="100%"
            mb={4}
          >
            <Flex
              width={"full"}
              alignItems={"center"}
              paddingX={8}
              columnGap={8}
            >
              <Circle
                size={24}
                backgroundColor={"black"}
                backgroundImage={
                  _.get(creator, "picture")
                    ? `url('${getPublicDownloadUrl(_.get(creator, "picture"))}')`
                    : null
                }
                backgroundSize={"cover"}
                backgroundPosition={"center"}
              />
              <SimpleGrid
                width={"full"}
                borderRadius={"md"}
                marginTop={4}
                columns={3}
                paddingY={4}
              >
                <Flex flexDirection={"column"} rowGap={2} alignItems={"center"}>
                  <Text fontSize={"xl"}>
                    {_.get(creator, "followersTotal", 0)}
                  </Text>
                  <Text fontSize={"xs"}>追蹤數</Text>
                </Flex>
                <Flex flexDirection={"column"} rowGap={2} alignItems={"center"}>
                  <Box fontSize={"xl"} textAlign="center">
                    {loading ? (
                      <CircularProgress size="20px" isIndeterminate />
                    ) : (
                      count
                    )}
                  </Box>
                  <Text fontSize={"xs"}>上架影片數</Text>
                </Flex>
                <Flex flexDirection={"column"} rowGap={2} alignItems={"center"}>
                  <Text fontSize={"xl"}>
                    {_.get(creator, "points.balance", 0)}
                  </Text>
                  <Text fontSize={"xs"}>鑽石數</Text>
                </Flex>
              </SimpleGrid>
            </Flex>
            <Flex width={"full"} paddingX={8}>
              <Box>
                <Text fontWeight={"bold"}>
                  {_.get(creator, "displayName", "未命名創作者")}
                </Text>
                <Flex alignItems="center">
                  <Text fontSize={"sm"} mr={2}>
                    @{_.get(creator, "slug", "anonymouse")}
                  </Text>
                  <Button size="xs" onClick={() => setIsEditingSlug(true)}>
                    編輯
                  </Button>
                </Flex>
                <Text fontSize={"sm"} color="gray.400">
                  加入日期：
                  {dayjs(_.get(creator, "createdAt")).format("YYYY-MM-DD")}
                </Text>

                {isEditingSlug ? (
                  <Box mt={2} mb={3}>
                    <Flex alignItems="center">
                      <Text mr={2}>@</Text>
                      <Textarea
                        size="sm"
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                        placeholder="請輸入slug"
                        resize="none"
                        rows={1}
                        maxW="200px"
                      />
                    </Flex>
                    <Flex mt={2}>
                      <Button
                        size="sm"
                        colorScheme="blue"
                        mr={2}
                        onClick={async () => {
                          if (!slug.trim()) {
                            toast({
                              title: "Slug不能為空",
                              status: "error",
                              duration: 3000,
                            });
                            return;
                          }

                          try {
                            const creatorRef = doc(firestore, "creators", id);
                            await updateDoc(creatorRef, {
                              slug: slug.trim(),
                            });
                            setIsEditingSlug(false);
                            toast({
                              title: "Slug已更新",
                              status: "success",
                              duration: 3000,
                            });
                          } catch (error) {
                            toast({
                              title: "更新失敗",
                              description: error.message,
                              status: "error",
                              duration: 5000,
                            });
                          }
                        }}
                      >
                        儲存
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => {
                          setSlug(_.get(creator, "slug", ""));
                          setIsEditingSlug(false);
                        }}
                      >
                        取消
                      </Button>
                    </Flex>
                  </Box>
                ) : null}

                <Button
                  colorScheme="red"
                  size="sm"
                  mt={2}
                  isLoading={isDeleting}
                  onClick={async () => {
                    if (
                      !window.confirm("確定要刪除此創作者嗎？此動作無法復原。")
                    ) {
                      return;
                    }

                    setIsDeleting(true);
                    try {
                      await deleteCreator(firestore, id);
                      toast({
                        title: "創作者已刪除",
                        status: "success",
                        duration: 3000,
                      });
                      navigate("/creators");
                    } catch (error) {
                      console.error("Error deleting creator:", error);
                      toast({
                        title: "刪除失敗",
                        description: error.message,
                        status: "error",
                        duration: 5000,
                      });
                    } finally {
                      setIsDeleting(false);
                    }
                  }}
                >
                  刪除創作者
                </Button>
              </Box>
              <Box flex={1}>
                <Box>{_.get(creator, "description")}</Box>
                <Box mt={4} ml={6}>
                  <Flex alignItems="center" mb={2}>
                    <Text fontWeight="bold" mr={2}>
                      自我介紹
                    </Text>
                    {!isEditingIntroduce && (
                      <Button
                        size="xs"
                        onClick={() => setIsEditingIntroduce(true)}
                      >
                        編輯
                      </Button>
                    )}
                  </Flex>
                  {isEditingIntroduce ? (
                    <Box>
                      <Textarea
                        value={introduce}
                        onChange={(e) => setIntroduce(e.target.value)}
                        placeholder="請輸入自我介紹"
                        mb={2}
                      />
                      <Button
                        size="sm"
                        colorScheme="blue"
                        mr={2}
                        onClick={async () => {
                          try {
                            const creatorRef = doc(firestore, "creators", id);
                            await updateDoc(creatorRef, {
                              introduce: introduce,
                            });
                            setIsEditingIntroduce(false);
                            toast({
                              title: "自我介紹已更新",
                              status: "success",
                              duration: 3000,
                            });
                          } catch (error) {
                            toast({
                              title: "更新失敗",
                              description: error.message,
                              status: "error",
                              duration: 5000,
                            });
                          }
                        }}
                      >
                        儲存
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => {
                          setIntroduce(_.get(creator, "introduce", ""));
                          setIsEditingIntroduce(false);
                        }}
                      >
                        取消
                      </Button>
                    </Box>
                  ) : (
                    <Text whiteSpace="pre-wrap">
                      {_.get(creator, "introduce", "尚未填寫自我介紹")}
                    </Text>
                  )}
                </Box>
              </Box>
            </Flex>
          </Flex>
        </Collapse>
        <Flex justifyContent="space-between" alignItems="center" mb={2} px={4}>
          {!isInfoExpanded && (
            <Text fontWeight="bold" fontSize="lg">
              {_.get(creator, "displayName", "未命名創作者")}
            </Text>
          )}
          <IconButton
            size="sm"
            icon={
              <FAIcon
                group="solid"
                name={isInfoExpanded ? "chevron-up" : "chevron-down"}
              />
            }
            onClick={() => setIsInfoExpanded(!isInfoExpanded)}
            aria-label={isInfoExpanded ? "收起創作者資訊" : "展開創作者資訊"}
            ml={isInfoExpanded ? "auto" : 0}
          />
        </Flex>
        <Tabs
          isLazy={true}
          marginTop={2}
          display="flex"
          flexDirection="column"
          onChange={() => {
            if (isInfoExpanded) setIsInfoExpanded(false);
          }}
        >
          <TabList>
            <Tab>影片</Tab>
            <Tab>追蹤者</Tab>
            <Tab>錢包紀錄</Tab>
            <Tab>聊天室</Tab>
          </TabList>

          <TabPanels overflow="auto">
            <TabPanel padding={0} height="200%" overflow="hidden">
              <VideosList id={id} onRemove={reduceVideosTotal} />
            </TabPanel>
            <TabPanel height="100%" overflow="auto">
              <FollowersList id={id} />
            </TabPanel>
            <TabPanel height="100%" overflow="auto">
              <CreatorWallet creator={creator} />
            </TabPanel>
            <TabPanel p={0} height="100%" overflow="hidden">
              <Flex overflow="hidden" maxHeight="80vh">
                <ChatsList creator={creator} />
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </SimpleGrid>
    </MainLayout>
  );
}
