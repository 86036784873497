import {
    Avatar,
    Box,
    Center,
    Flex,
    HStack,
    Image,
    Menu,
    MenuItem,
    MenuList,
    Text,
    useToast,
    VStack,
    MenuButton,
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import VideoPlayer from "./VideoPlayer";
import FAIcon from "./FAIcon";
import {deleteDoc, doc, getDoc, collection, query, where, getDocs, updateDoc, deleteField} from "firebase/firestore";
import {useEffect, useState, useRef} from "react";
import getPublicDownloadUrl from "libs/get-public-download-url";
import canonicalDate from "libs/canonical-date";
import {useFirebase} from "../context/firebase.context";

export default function ChatMessage({message, creator, onAvatarClick, onMessageDeleted}) {
    const [user, setUser] = useState(null);
    const {firestore} = useFirebase();
    const [activeVideo, setActiveVideo] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();

    useEffect(() => {
        const fetchUser = async () => {
            if (message.sender.type !== "user") return;

            const docRef = doc(firestore, "users", message.sender.id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setUser({id: docSnap.id, ...docSnap.data()});
            }
        };

        fetchUser();
    }, [message.sender.id, message.sender.type, firestore]);

    const handleDeleteMessage = async () => {
        if (!message.id) return;

        try {
            setIsDeleting(true);
            const messageRef = doc(firestore, "chatroom-messages", message.id);
            await deleteDoc(messageRef);

            // 順便刪掉firestore中的 chatrooms 表，lastMessage.id == message.id的chatroom中的lastMessage field
            const chatroomsRef = collection(firestore, "chatrooms");
            const q = query(chatroomsRef, where("lastMessage.id", "==", message.id));
            const matchingChatroomsSnapshot = await getDocs(q);
            matchingChatroomsSnapshot.forEach(async (chatroom) => {
                const chatroomRef = doc(firestore, "chatrooms", chatroom.id);
                await updateDoc(chatroomRef, {
                    lastMessage: deleteField()
                });
            });

            // 找firestore中的 chatrooms 表，lastMessage.id == message.id 的 chatroom
            // 如果有找到，代表此訊息被刪了，那麼lastMessage要換成上一個沒被刪的message；如果沒找到message，則只要刪掉這筆chatroom的lastMessage field
            // Use a query to filter chatrooms where lastMessage.id equals message.id
            // const chatroomsRef = collection(firestore, "chatrooms");
            // const q = query(chatroomsRef, where("lastMessage.id", "==", message.id));
            // const matchingChatroomsSnapshot = await getDocs(q);

            // // Now only process the matching chatrooms
            // matchingChatroomsSnapshot.forEach(async (chatroom) => {
            // try {
            //     // Try to find a previous message to use as the new lastMessage
            //     const messagesRef = collection(firestore, "chatroom-messages");
            //     const messagesQuery = query(
            //     messagesRef,
            //         where("chatroomId", "==", chatroom.id),
            //         orderBy("createdAt", "desc"),
            //         limit(2)  // Get 2 in case the first one is the one being deleted
            //     );
                
            //     const messagesSnapshot = await getDocs(messagesQuery);
                
            //     if (!messagesSnapshot.empty) {
            //         // Find a message that isn't the one being deleted
            //         const newLastMessage = messagesSnapshot.docs.find(
            //             doc => doc.id !== message.id
            //         );
                    
            //         if (newLastMessage) {
            //             // Update with the new last message
            //             await updateDoc(doc(firestore, "chatrooms", chatroom.id), {
            //             lastMessage: newLastMessage.data()
            //             });
            //         } else {
            //             // If we didn't find another message, remove the lastMessage field
            //             await updateDoc(doc(firestore, "chatrooms", chatroom.id), {
            //             lastMessage: deleteField()
            //             });
            //         }
            //         }
            //     } catch (error) {
            //         console.error(`Error updating chatroom ${chatroom.id}:`, error);
            //     }
            // });


            toast({
                title: "訊息已刪除",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

            if (onMessageDeleted) {
                onMessageDeleted(message.id);
            }
        } catch (error) {
            console.error("刪除訊息失敗:", error);
            toast({
                title: "刪除訊息失敗",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsDeleting(false);
        }
    };

    // 檢查當前用戶是否有權限刪除訊息
    const canDelete = true;

    // 處理右鍵選單
    const handleContextMenu = (e) => {
        if (canDelete) {
            e.preventDefault(); // 防止默認右鍵選單
            setShowMenu(true);
        }
    };

    // 處理點擊右側區域
    const handleRightSideClick = (e) => {
        const boxWidth = e.currentTarget.offsetWidth;
        const clickX = e.nativeEvent.offsetX;
        // 如果點擊位置在訊息右側 20% 的區域，顯示選單
        if (clickX > boxWidth * 0.8 && canDelete) {
            e.stopPropagation();
            setShowMenu(true);
        }
    };

    return (
        <Flex
            key={message.id}
            gap={3}
            align="start"
            flexDirection={message.sender.type === "user" ? "row" : "row-reverse"}
        >
            {message.sender.type === "user" && (
                <Avatar
                    size="sm"
                    src={getPublicDownloadUrl(user?.picture)}
                    cursor="pointer"
                    onClick={() => onAvatarClick && user?.id && onAvatarClick(user.id)}
                    _hover={{
                        boxShadow: "0 0 0 2px #4299E1",
                        transform: "scale(1.05)",
                    }}
                    transition="all 0.2s"
                />
            )}
            <Menu isOpen={showMenu} onClose={() => setShowMenu(false)}>
                <Box
                    as={MenuButton}
                    bg={message.sender.type === "user" ? "gray.700" : "blue.500"}
                    p={3}
                    borderRadius="lg"
                    maxW="70%"
                    position="relative"
                    _hover={{
                        boxShadow: canDelete ? "0 0 0 1px rgba(255,255,255,0.2)" : "none",
                    }}
                    transition="all 0.2s"
                    onContextMenu={handleContextMenu}
                    onClick={handleRightSideClick}
                >
                    {message.type === "text" && (
                        <VStack align="start" spacing={1}>
                            <Text>{message.text}</Text>
                        </VStack>
                    )}
                    {message.type === "gift" && (
                        <VStack align="start" spacing={1}>
                            <Text>送出禮物：{message.gift}</Text>
                            <Center
                                px={2}
                                py={1}
                                bg="blackAlpha.700"
                                borderRadius="md"
                                alignSelf="start"
                            >
                                <HStack spacing={1}>
                                    <FAIcon name="diamond"/>
                                    <Text>{message.income} 鑽石</Text>
                                </HStack>
                            </Center>
                        </VStack>
                    )}
                    {message.type === "video" && (
                        <VStack align="start" spacing={1}>
                            <Text>{message.text}</Text>
                            <Box
                                position="relative"
                                w="200px"
                                h="356px"
                                cursor="pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setActiveVideo(message);
                                }}
                                onContextMenu={(e) => e.stopPropagation()}
                            >
                                <Image
                                    src={getPublicDownloadUrl(message.thumbnail?.clear)}
                                    objectFit="cover"
                                    w="100%"
                                    h="100%"
                                    borderRadius="md"
                                />
                                <Center
                                    position="absolute"
                                    top={0}
                                    right={0}
                                    px={2}
                                    py={1}
                                    bg="blackAlpha.700"
                                    borderRadius="md"
                                >
                                    <HStack spacing={1}>
                                        <FAIcon name={message.isUnlocked ? "unlock" : "lock"}/>
                                        <Text>{message.price} 鑽石</Text>
                                    </HStack>
                                </Center>
                            </Box>
                        </VStack>
                    )}
                    {message.type === "image" && (
                        <VStack align="start" spacing={1}>
                            <Text>{message.text}</Text>
                            <Box position="relative" w="200px" h="200px">
                                <Image
                                    src={getPublicDownloadUrl(message.image?.clear)}
                                    objectFit="cover"
                                    w="100%"
                                    h="100%"
                                    borderRadius="md"
                                />
                                <Center
                                    position="absolute"
                                    top={0}
                                    right={0}
                                    px={2}
                                    py={1}
                                    bg="blackAlpha.700"
                                    borderRadius="md"
                                >
                                    <HStack spacing={1}>
                                        <FAIcon name={message.isUnlocked ? "unlock" : "lock"}/>
                                        <Text>{message.price} 鑽石</Text>
                                    </HStack>
                                </Center>
                            </Box>
                        </VStack>
                    )}
                    {message.type === "audio" && (
                        <HStack spacing={2} align="center">
                            <audio
                                src={getPublicDownloadUrl(message.audio)}
                                controls
                                style={{
                                    width: "140px",
                                    height: "32px",
                                }}
                            />
                            {!message.isUnlocked && (
                                <HStack spacing={1}>
                                    <FAIcon name="lock"/>
                                    <Text>{message.price} 鑽石</Text>
                                </HStack>
                            )}
                        </HStack>
                    )}
                    <HStack fontSize="xs" color="gray.400" mt={1} spacing={2}>
                        <Text>{canonicalDate(message.createdAt)}</Text>
                        {message.sender.type === "user" && message.income > 0 && (
                            <Text>{message.income} 鑽石</Text>
                        )}
                    </HStack>
                </Box>
                <MenuList bg="gray.800" borderColor="gray.700">
                    <MenuItem 
                        onClick={onOpen} 
                        isDisabled={isDeleting}
                        color="red.300"
                        icon={<FAIcon name="trash"/>}
                        _hover={{ bg: "gray.700" }}
                    >
                        刪除訊息
                    </MenuItem>
                </MenuList>
            </Menu>

            {/* 確認刪除的 Alert Dialog */}
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent bg="gray.800" color="white">
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            刪除訊息
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            確定要刪除這則訊息嗎？此操作無法撤銷。
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                取消
                            </Button>
                            <Button 
                                colorScheme="red" 
                                onClick={() => {
                                    handleDeleteMessage();
                                    onClose();
                                }} 
                                ml={3}
                                isLoading={isDeleting}
                            >
                                刪除
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {activeVideo && (
                <Box
                    position="fixed"
                    top={0}
                    left={0}
                    height="100vh"
                    width="full"
                    zIndex={101}
                >
                    <VideoPlayer
                        autoPlay={true}
                        creatorId={creator?.id}
                        video={activeVideo}
                        streamUrl={getPublicDownloadUrl(activeVideo.video?.path)}
                        downloadUrl={getPublicDownloadUrl(activeVideo.video?.path)}
                        onClose={() => setActiveVideo(null)}
                        options={{
                            close: true,
                        }}
                    />
                </Box>
            )}
        </Flex>
    );
}
