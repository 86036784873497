import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth, RecaptchaVerifier } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getMessaging } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

const IS_EMULATOR = false;

const app = initializeApp({
  apiKey: IS_EMULATOR ? "test-api-key" : process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const auth = getAuth(app);
const storage = getStorage(app);
const firestore = getFirestore(app);

const functions = getFunctions(app);
let messaging = (() => {
  try {
    return getMessaging(app);
  } catch (e) {
    return null;
  }
})();

if (IS_EMULATOR) {
  if (!auth.config.emulator) {
    connectAuthEmulator(auth, "http://127.0.0.1:9099", {
      disableWarnings: true,
    });
  }
  if (!firestore._settingsFrozen) {
    connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
  }
  connectStorageEmulator(storage, "127.0.0.1", 9199);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}
// recaptcha verifier
const createVerifier = (id, callback) =>
  new RecaptchaVerifier(auth, id, {
    size: "invisible",
    callback,
  });

const firebase = {
  app,
  auth,
  firestore,
  storage,
  messaging,
  functions,
  analytics: IS_EMULATOR ? null : getAnalytics(app),
  createVerifier,
};

export default firebase;
