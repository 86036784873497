import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import canonicalDate from "libs/canonical-date";
import { useUser } from "hooks/useUser";
import getPublicDownloadUrl from "libs/get-public-download-url";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useFirebase } from "context/firebase.context";

export default function ChatItem({ chat, creator, onClick }) {
  const user = useUser(chat.userId);
  const lastMessage = chat.lastMessage;
  const { firestore } = useFirebase();
  const [chatCreator, setChatCreator] = useState(null);

  useEffect(() => {
    const fetchCreator = async () => {
      if (!chat.creatorId) return;

      // If the creator is already provided and matches the chat's creatorId, use it
      if (creator && creator.id === chat.creatorId) {
        setChatCreator(creator);
        return;
      }

      try {
        const docRef = doc(firestore, "creators", chat.creatorId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setChatCreator({ id: docSnap.id, ...docSnap.data() });
        }
      } catch (error) {
        console.error("Error fetching creator:", error);
      }
    };

    fetchCreator();
  }, [chat.creatorId, creator, firestore]);

  return (
    <Flex
      p={3}
      borderBottomWidth={1}
      gap={3}
      align="center"
      cursor="pointer"
      _hover={{ bg: "gray.700" }}
      onClick={onClick}
    >
      <Avatar src={getPublicDownloadUrl(user?.picture)} />
      <Box flex={1}>
        <Text pb={1}>{user?.displayName || "Loading..."}</Text>
        {chatCreator && (
          <Text fontSize="xs" color="blue.300" pb={1}>
            創作者: {chatCreator.displayName}
          </Text>
        )}
        <Text noOfLines={1}>
          {lastMessage?.sender?.type === "user"
            ? user?.displayName || "用戶"
            : chatCreator?.displayName || creator?.displayName}
          ： {lastMessage?.text}
          {lastMessage?.type !== "text" && lastMessage?.text && " | "}
          {lastMessage?.type === "gift" && "禮物"}
          {lastMessage?.type === "video" && "影片"}
          {lastMessage?.type === "audio" && "錄音"}
        </Text>
      </Box>
      <Text>{canonicalDate(lastMessage?.createdAt)}</Text>
    </Flex>
  );
}
