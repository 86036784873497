import { Box, VStack } from "@chakra-ui/react";
import ChatMessage from "components/ChatMessage";
import { useEffect } from "react";

const MessageList = ({
  messages,
  creator,
  messageClassName,
  onAvatarClick,
}) => {
  // 添加日志以跟踪消息列表变化
  useEffect(() => {
    console.log(
      "MessageList received new messages array:",
      messages ? messages.length : 0,
    );
    if (messages && messages.length > 0) {
      console.log(`Rendering MessageList with ${messages.length} messages`);
      console.log(
        `First message ID: ${messages[0].id}, Last message ID: ${messages[messages.length - 1].id}`,
      );

      // 记录所有消息ID以便调试
      if (messages.length > 30) {
        console.log("All message IDs:", messages.map((m) => m.id).join(", "));
      }

      // 检查是否有重复的消息ID
      const messageIds = messages.map((m) => m.id);
      const uniqueIds = new Set(messageIds);
      if (uniqueIds.size !== messages.length) {
        console.warn(
          `Found ${messages.length - uniqueIds.size} duplicate message IDs!`,
        );

        // 找出重复的ID
        const counts = {};
        messageIds.forEach((id) => {
          counts[id] = (counts[id] || 0) + 1;
        });

        const duplicates = Object.entries(counts)
          .filter(([_, count]) => count > 1)
          .map(([id, count]) => `${id} (${count}次)`);

        console.warn(`Duplicate IDs: ${duplicates.join(", ")}`);
      }
    }
  }, [messages]);

  if (!messages || messages.length === 0) {
    return (
      <Box textAlign="center" color="gray.500" py={4}>
        暫無訊息
      </Box>
    );
  }

  return (
    <VStack align="stretch" spacing={4}>
      {messages.map((message, index) => (
        <Box
          key={message.id || index}
          className={messageClassName}
          data-message-id={message.id}
          data-index={index}
        >
          <ChatMessage
            message={message}
            creator={creator}
            onAvatarClick={onAvatarClick}
          />
        </Box>
      ))}
    </VStack>
  );
};

export default MessageList;
