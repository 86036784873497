import {Box, Button, Center, Circle, CircularProgress, Flex, Input, SimpleGrid, Text,} from "@chakra-ui/react";
import _ from "lodash";
import {ulid} from "ulid";
import {Link, useNavigate} from "react-router-dom";
import FAIcon from "@components/FAIcon";
import getPublicDownloadUrl from "libs/get-public-download-url";
import MainLayout from "@layouts/main.layout";
import {useCreators} from "hooks/useCreators";
import {useMemo, useState} from "react";
import VideoCount from "components/VideoCount";
import dayjs from "dayjs";

export default function CreatorsIndexPage() {
    const navigate = useNavigate();
    const {creators, isLoading} = useCreators();
    const [keyword, setKeyword] = useState("");
    const [sortBy, setSortBy] = useState(() => {
        // Get saved sort preference from localStorage or default to "points"
        return localStorage.getItem("creatorsPageSortBy") || "points";
    }); // points, followers, videos, joinDate

    const filteredCreators = useMemo(
        () =>
            creators
                .filter((creator) => {
                    if (!keyword) return true;
                    const {id, displayName, slug} = creator;
                    return (
                        id.includes(keyword) ||
                        displayName.includes(keyword) ||
                        slug.includes(keyword)
                    );
                })
                .sort((a, b) => {
                    switch (sortBy) {
                        case "followers":
                            return (b.followersTotal || 0) - (a.followersTotal || 0);
                        case "joinDate":
                            return (b.createdAt || 0) - (a.createdAt || 0);
                        case "points":
                        default:
                            return (b.points?.balance || 0) - (a.points?.balance || 0);
                    }
                }),
        [creators, keyword, sortBy],
    );

    return (
        <MainLayout>
            <SimpleGrid paddingX={2} rowGap={2} marginTop={2}>
                <Flex justifyContent="space-between" gap={2}>
                    <Flex flex={1} gap={2}>
                        <Box width="200px">
                            <select
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    borderRadius: "6px",
                                    backgroundColor: "#2D3748",
                                    color: "white",
                                    border: "1px solid #4A5568",
                                }}
                                value={sortBy}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setSortBy(value);
                                    localStorage.setItem("creatorsPageSortBy", value);
                                }}
                            >
                                <option value="points">依鑽石數排序</option>
                                <option value="followers">依追蹤數排序</option>
                                <option value="joinDate">依加入日期排序</option>
                            </select>
                        </Box>
                        <Input
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            placeholder="暱稱 / ID"
                        />
                    </Flex>
                    <Flex>
                        <Link to="/creators/new">
                            <Button colorScheme="blue">新增創作者</Button>
                        </Link>
                    </Flex>
                </Flex>
                <SimpleGrid spacing={2}>
                    {isLoading && (
                        <Center mt={6}>
                            <CircularProgress isIndeterminate/>
                        </Center>
                    )}
                    {!isLoading &&
                        filteredCreators.map((creator) => {
                            return (
                                <Box
                                    onClick={() => {
                                        const creatorId = _.get(creator, "id");
                                        if (creatorId) {
                                            navigate(`/creators/${creatorId}`);
                                        }
                                    }}
                                    cursor={"pointer"}
                                    key={_.get(creator, "id", ulid())}
                                    backgroundColor={"gray.600"}
                                    padding={4}
                                    borderRadius={"md"}
                                >
                                    <Flex columnGap={4}>
                                        <Circle
                                            size={12}
                                            backgroundColor={"black"}
                                            backgroundImage={
                                                _.get(creator, "picture")
                                                    ? `url('${getPublicDownloadUrl(_.get(creator, "picture"))}')`
                                                    : null
                                            }
                                            backgroundSize={"cover"}
                                            backgroundPosition={"center"}
                                        ></Circle>
                                        <Box>
                                            <Flex alignItems={"center"}>
                                                <Text fontWeight={"semibold"}>
                                                    {_.get(creator, "displayName", "未命名的創作者")}{" "}
                                                    <Text as={"span"} color={"green.200"}>
                                                        <FAIcon group={"solid"} name="check-circle"/>
                                                    </Text>
                                                </Text>
                                            </Flex>
                                            <Text fontSize={"xs"}>
                                                @{_.get(creator, "slug", "anonymouse")}
                                            </Text>
                                            <Box marginTop={4}>
                                                {_.get(creator, "description", null)}
                                            </Box>
                                        </Box>
                                    </Flex>
                                    <SimpleGrid
                                        marginTop={4}
                                        columns={4}
                                        borderTopWidth={"1px"}
                                        borderTopColor={"gray.800"}
                                        paddingTop={4}
                                    >
                                        <Flex
                                            flexDirection={"column"}
                                            rowGap={2}
                                            alignItems={"center"}
                                        >
                                            <Text fontSize={"2xl"}>
                                                {_.get(creator, "followersTotal", 0)}
                                            </Text>
                                            <Text fontSize={"sm"}>追蹤數</Text>
                                        </Flex>
                                        <Flex
                                            flexDirection={"column"}
                                            rowGap={2}
                                            alignItems={"center"}
                                        >
                                            <VideoCount creatorId={creator.id}/>
                                            <Text fontSize={"sm"}>上架影片數</Text>
                                        </Flex>
                                        <Flex
                                            flexDirection={"column"}
                                            rowGap={2}
                                            alignItems={"center"}
                                        >
                                            <Text fontSize={"2xl"}>
                                                {_.get(creator, "points.balance", 0)}
                                            </Text>
                                            <Text fontSize={"sm"}>鑽石數</Text>
                                        </Flex>
                                        <Flex
                                            flexDirection={"column"}
                                            rowGap={2}
                                            alignItems={"center"}
                                        >
                                            <Text fontSize={"2xl"}>
                                                {dayjs(_.get(creator, "createdAt")).format(
                                                    "YYYY-MM-DD",
                                                )}
                                            </Text>
                                            <Text fontSize={"sm"}>加入日期</Text>
                                        </Flex>
                                    </SimpleGrid>
                                </Box>
                            );
                        })}
                </SimpleGrid>
            </SimpleGrid>
        </MainLayout>
    );
}
