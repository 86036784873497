import {
  AspectRatio,
  Box,
  Button,
  Center,
  Circle,
  CircularProgress,
  Fade,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import MainLayout from "@layouts/main.layout";
import FAIcon from "components/FAIcon";
import VideoSelector from "components/VideoSelector";
import { useFirebase } from "context/firebase.context";
import { useCallback, useEffect, useState } from "react";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import useAPI from "hooks/api";

const VideoItem = ({ id, thumbnail, onRemove }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      position="relative"
      mb={3}
      bg={isDragging ? "gray.700" : "transparent"}
      p={2}
      borderRadius="md"
      {...attributes}
    >
      <Flex align="center" gap={4}>
        <Center cursor="grab" px={2} {...listeners}>
          <FAIcon name="grip-vertical" />
        </Center>
        <Box position="relative">
          <AspectRatio ratio={9 / 16} width="120px">
            <Image
              src={thumbnail}
              objectFit="cover"
              borderRadius="md"
              cursor="grab"
              {...listeners}
            />
          </AspectRatio>
          <Circle
            size={5}
            position="absolute"
            bg="red.400"
            top={-2}
            right={-2}
            role="button"
            onClick={onRemove}
          >
            <FAIcon name="close" />
          </Circle>
        </Box>
      </Flex>
    </Box>
  );
};

export default function GuestModePage() {
  const api = useAPI();
  const [isDesktop] = useMediaQuery("(min-width: 992px)");
  const { firestore } = useFirebase();
  const [videos, setVideos] = useState([]);
  const [updateStatus, setUpdateStatus] = useState("IDLE");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [guestModeRef] = useState(() => doc(firestore, "app", "guest-mode"));
  const [videoIds, setVideoIds] = useState([]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    const loadVideoDetails = async (ids) => {
      try {
        const videoPromises = ids.map(async (id) => {
          const response = await api.getVideo(id);
          if (response && !response.error) {
            return {
              id,
              thumbnail: response.thumbnail,
              thumbnails: response.thumbnails,
            };
          }
          return null;
        });

        const videoDetails = await Promise.all(videoPromises);
        setVideos(videoDetails.filter((v) => v !== null));
      } catch (error) {
        console.error("Error loading video details:", error);
        setVideos([]);
      }
    };

    const unsubscribe = onSnapshot(guestModeRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        const ids = data.videos || [];
        setVideoIds(ids);
        loadVideoDetails(ids);
      } else {
        setDoc(guestModeRef, { videos: [] });
        setVideoIds([]);
        setVideos([]);
      }
    });

    return () => unsubscribe();
  }, [firestore, api, guestModeRef]);

  const addVideo = useCallback(
    async (video) => {
      try {
        setUpdateStatus("UPDATING");
        onClose();

        const updatedVideoIds = [video.id, ...videoIds];
        await setDoc(guestModeRef, { videos: updatedVideoIds });

        setUpdateStatus("DONE");
        setTimeout(() => setUpdateStatus("IDLE"), 1000);
      } catch (error) {
        console.error("Error adding video:", error);
        setUpdateStatus("IDLE");
      }
    },
    [guestModeRef, videoIds, onClose],
  );

  const removeVideo = useCallback(
    async (videoId) => {
      try {
        setUpdateStatus("UPDATING");

        const updatedVideoIds = videoIds.filter((id) => id !== videoId);
        await setDoc(guestModeRef, { videos: updatedVideoIds });

        setUpdateStatus("DONE");
        setTimeout(() => setUpdateStatus("IDLE"), 1000);
      } catch (error) {
        console.error("Error removing video:", error);
        setUpdateStatus("IDLE");
      }
    },
    [guestModeRef, videoIds],
  );

  return (
    <MainLayout p={3}>
      <Box maxW={1200} mx="auto">
        <Flex justify="space-between" align="center" mb={6}>
          <Text fontSize="2xl">訪客模式影片設定</Text>
          <Button onClick={onOpen} colorScheme="blue">
            新增影片
          </Button>
        </Flex>

        {videos === null ? (
          <Center mt={6}>
            <CircularProgress isIndeterminate size={12} />
          </Center>
        ) : (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={(event) => {
              const { active, over } = event;
              if (!over || active.id === over.id) return;

              setUpdateStatus("UPDATING");
              const oldIndex = videos.findIndex((v) => v.id === active.id);
              const newIndex = videos.findIndex((v) => v.id === over.id);

              const newVideos = arrayMove(videos, oldIndex, newIndex);
              const videoIds = newVideos.map((v) => v.id);

              setDoc(guestModeRef, { videos: videoIds }).then(() => {
                setVideos(newVideos);
                setUpdateStatus("DONE");
                setTimeout(() => setUpdateStatus("IDLE"), 1000);
              });
            }}
          >
            <SortableContext
              items={videos.map((v) => v.id)}
              strategy={verticalListSortingStrategy}
            >
              <VStack align="stretch" spacing={2} width="100%" mb={4}>
                {videos.map((video) => (
                  <VideoItem
                    key={`video-${video.id}`}
                    {...video}
                    onRemove={() => removeVideo(video.id)}
                  />
                ))}
              </VStack>
            </SortableContext>
          </DndContext>
        )}
      </Box>

      <Fade in={updateStatus !== "IDLE"} unmountOnExit>
        <Box position="fixed" bottom={0} right={0} p={4} bg="blackAlpha.500">
          {updateStatus === "UPDATING" && (
            <Flex align="center" gap={2} color="white">
              更新中...
              <Spinner />
            </Flex>
          )}
          {updateStatus === "DONE" && (
            <Flex align="center" gap={2} color="white">
              已同步
              <FAIcon name="check" />
            </Flex>
          )}
        </Box>
      </Fade>

      <Modal isOpen={isOpen} onClose={onClose} size={isDesktop ? "md" : "xs"}>
        <ModalOverlay />
        <ModalBody>
          <ModalContent>
            <VideoSelector onSelect={addVideo} />
          </ModalContent>
        </ModalBody>
      </Modal>
    </MainLayout>
  );
}
