import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  where,
} from "firebase/firestore";
import { deleteVideo } from "./delete-video";

export const deleteCreator = async (firestore, creatorId) => {
  const creatorRef = doc(firestore, "creators", creatorId);
  const creatorSnap = await getDoc(creatorRef);

  if (!creatorSnap.exists()) {
    throw new Error("Creator not found");
  }

  const creatorData = creatorSnap.data();
  const userId = creatorData.userId;

  // Get all related collections
  const videosQuery = query(
    collection(firestore, "videos"),
    where("creatorId", "==", creatorId),
  );
  const followersQuery = query(
    collection(firestore, "creator-followers"),
    where("creatorId", "==", creatorId),
  );
  const cannedMsgsQuery = query(
    collection(firestore, "canned-messages"),
    where("creatorId", "==", creatorId),
  );
  const msgSchedulesQuery = query(
    collection(firestore, "canned-message-schedule"),
    where("creatorId", "==", creatorId),
  );

  await runTransaction(firestore, async (transaction) => {
    // Delete related collections
    const deleteCollection = async (q) => {
      const snap = await getDocs(q);
      snap.docs.forEach((doc) => {
        transaction.delete(doc.ref);
      });
    };

    await deleteCollection(followersQuery);
    await deleteCollection(cannedMsgsQuery);
    await deleteCollection(msgSchedulesQuery);

    // Update creator document
    transaction.update(creatorRef, {
      displayName: "已刪除的創作者",
      deleted: true,
      deletedAt: Date.now(),
      archivedData: creatorData,
      userId: null,
      //random 6 char string dont use crypto
      slug: `dc_${Math.random().toString(36).substring(2, 8)}`,
      introduce: "",
      picture: "",
    });

    // Update user if exists
    if (userId) {
      const userRef = doc(firestore, "users", userId);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        transaction.update(userRef, {
          "roles.creator": false,
          pushTargets: (userData.pushTargets || [])
            .filter((p) => !p.startsWith("creator_"))
            .filter((p) => p !== "creators"),
          creatorId: null,
        });
      }
    }
  });

  // Handle videos separately
  const videosSnap = await getDocs(videosQuery);
  for (const videoDoc of videosSnap.docs) {
    await deleteVideo(firestore, videoDoc.id);
  }
};
