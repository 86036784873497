import {
  Avatar,
  Box,
  Button,
  Center,
  Checkbox,
  CircularProgress,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import { useFirebase } from "context/firebase.context";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";
import getPublicDownloadUrl from "libs/get-public-download-url";

export default function AutoFollowPage() {
  const { firestore } = useFirebase();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [autoFollowCreators, setAutoFollowCreators] = useState([]); // creator IDs
  const [autoFollowCreatorDetails, setAutoFollowCreatorDetails] = useState([]); // full creator objects
  const [showCreatorSelector, setShowCreatorSelector] = useState(false);
  const [creators, setCreators] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedCreators, setSelectedCreators] = useState([]);

  // Filter out creators that are already in auto-follow list
  const availableCreators = useMemo(() => {
    return creators.filter(
      (creator) => !autoFollowCreators.includes(creator.id),
    );
  }, [creators, autoFollowCreators]);

  const filteredCreators = useMemo(() => {
    if (!search) return availableCreators;
    const searchLower = search.toLowerCase();
    return availableCreators.filter(
      (creator) =>
        creator.displayName?.toLowerCase().includes(searchLower) ||
        creator.slug?.toLowerCase().includes(searchLower),
    );
  }, [availableCreators, search]);

  const handleSelect = (creator) => {
    setSelectedCreators((prev) => {
      const exists = prev.find((c) => c.id === creator.id);
      if (exists) {
        return prev.filter((c) => c.id !== creator.id);
      }
      return [...prev, creator];
    });
  };

  const handleConfirm = async () => {
    try {
      const docRef = doc(firestore, "app", "auto-follow");
      // 先獲取最新狀態
      const docSnap = await getDoc(docRef);
      const currentCreators = docSnap.exists()
        ? docSnap.data().creators || []
        : [];

      // 使用最新狀態來更新
      const updatedCreators = [
        ...currentCreators,
        ...selectedCreators.map((c) => c.id),
      ];
      await setDoc(docRef, { creators: updatedCreators });

      // 重新載入以確保資料同步
      await loadCreators();
      toast({
        title: "新增成功",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      console.error("Error adding creators:", error);
      toast({
        title: "新增失敗",
        status: "error",
        duration: 3000,
      });
    }
    setSelectedCreators([]);
    setSearch("");
    setShowCreatorSelector(false);
  };

  const loadCreators = useCallback(async () => {
    setLoading(true);
    try {
      const docRef = doc(firestore, "app", "auto-follow");
      const docSnap = await getDoc(docRef);
      const creatorIds = docSnap.exists() ? docSnap.data().creators || [] : [];

      // Get creator details for each ID
      const creatorDetails = [];
      for (const id of creatorIds) {
        const creatorDoc = await getDoc(doc(firestore, "creators", id));
        if (creatorDoc.exists()) {
          creatorDetails.push({
            id: creatorDoc.id,
            ...creatorDoc.data(),
          });
        }
      }

      setAutoFollowCreators(creatorIds);
      setAutoFollowCreatorDetails(creatorDetails);

      if (!docSnap.exists()) {
        // Initialize document if it doesn't exist
        await setDoc(docRef, { creators: [] });
      }
    } catch (error) {
      console.error("Error loading creators:", error);
      toast({
        title: "載入失敗",
        status: "error",
        duration: 3000,
      });
    }
    setLoading(false);
  }, [firestore, toast]);

  // Load creators
  useEffect(() => {
    const fetchCreators = async () => {
      const creatorsRef = collection(firestore, "creators");
      const creatorsSnap = await getDocs(creatorsRef);
      const creatorsData = creatorsSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCreators(creatorsData);
    };
    fetchCreators();
  }, [firestore]);

  const removeCreator = useCallback(
    async (creatorId) => {
      try {
        const docRef = doc(firestore, "app", "auto-follow");
        const updatedCreators = autoFollowCreators.filter(
          (id) => id !== creatorId,
        );
        await setDoc(docRef, { creators: updatedCreators });
        setAutoFollowCreators(updatedCreators);

        // Update creator details
        setAutoFollowCreatorDetails((prev) =>
          prev.filter((creator) => creator.id !== creatorId),
        );

        toast({
          title: "刪除成功",
          status: "success",
          duration: 3000,
        });
      } catch (error) {
        console.error("Error removing creator:", error);
        toast({
          title: "刪除失敗",
          status: "error",
          duration: 3000,
        });
      }
    },
    [autoFollowCreators, firestore, toast],
  );

  useEffect(() => {
    loadCreators();
  }, [loadCreators]);

  return (
    <MainLayout p={3}>
      <Modal
        isOpen={showCreatorSelector}
        onClose={() => setShowCreatorSelector(false)}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box p={4}>
              <Input
                placeholder="搜尋創作者名稱或 slug"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                mb={4}
              />
              <Box maxH="60vh" overflowY="auto">
                <SimpleGrid columns={3} gap={4}>
                  {filteredCreators.map((creator) => (
                    <VStack
                      key={creator.id}
                      cursor="pointer"
                      onClick={() => handleSelect(creator)}
                      p={2}
                      borderRadius="md"
                      position="relative"
                      opacity={
                        selectedCreators.find((c) => c.id === creator.id)
                          ? 0.7
                          : 1
                      }
                    >
                      <Avatar
                        src={getPublicDownloadUrl(creator.picture)}
                        size="xl"
                      />
                      <Text fontSize="sm" noOfLines={1}>
                        {creator.displayName}
                      </Text>
                      <Checkbox
                        position="absolute"
                        top={2}
                        right={2}
                        isChecked={
                          !!selectedCreators.find((c) => c.id === creator.id)
                        }
                        onChange={() => handleSelect(creator)}
                      />
                    </VStack>
                  ))}
                </SimpleGrid>
              </Box>
              <ModalFooter px={0}>
                <Button
                  colorScheme="blue"
                  onClick={handleConfirm}
                  isDisabled={selectedCreators.length === 0}
                >
                  新增 ({selectedCreators.length})
                </Button>
              </ModalFooter>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box maxW="800px" mx="auto">
        <Flex mb={4}>
          <Button
            colorScheme="blue"
            onClick={() => {
              setSelectedCreators([]);
              setShowCreatorSelector(true);
            }}
          >
            選擇創作者
          </Button>
        </Flex>

        {loading ? (
          <Center mt={6}>
            <CircularProgress isIndeterminate size={12} />
          </Center>
        ) : (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>頭像</Th>
                <Th>名稱</Th>
                <Th>Slug</Th>
                <Th width="100px">操作</Th>
              </Tr>
            </Thead>
            <Tbody>
              {autoFollowCreatorDetails.map((creator) => (
                <Tr key={creator.id}>
                  <Td>
                    <Avatar
                      src={getPublicDownloadUrl(creator.picture)}
                      size="sm"
                    />
                  </Td>
                  <Td>{creator.displayName}</Td>
                  <Td>{creator.slug}</Td>
                  <Td>
                    <Button
                      colorScheme="red"
                      size="sm"
                      onClick={() => removeCreator(creator.id)}
                    >
                      刪除
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </MainLayout>
  );
}
