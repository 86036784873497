import {Box, Center, CircularProgress, Flex} from "@chakra-ui/react";
import {collection, getDocs, limit, onSnapshot, orderBy, query, startAfter, where,} from "firebase/firestore";
import {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";

const ChatDetail = ({
                        chatId,
                        creator,
                        firestore,
                        messages,
                        setMessages,
                    }) => {
    const navigate = useNavigate();
    const [oldestMessageTimestamp, setOldestMessageTimestamp] = useState(null);
    const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const messagesContainerRef = useRef(null);

    // 使用ref来存储已加载的消息ID，避免重复
    const loadedMessageIdsRef = useRef(new Set());

    // Load initial messages with real-time updates
    useEffect(() => {
        if (!chatId) return;

        // Set loading state
        setMessages(null);
        // 重置已加载消息ID集合
        loadedMessageIdsRef.current = new Set();
        console.log("Loading initial messages for chatId:", chatId);

        const q = query(
            collection(firestore, "chatroom-messages"),
            where("chatroomId", "==", chatId),
            orderBy("createdAt", "desc"),
            limit(30),
        );

        const unsubscribe = onSnapshot(q, {
            next: (snapshot) => {
                console.log("Snapshot received with", snapshot.docs.length, "messages");

                const messagesData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                    formattedTime:
                        doc.data().createdAt &&
                        typeof doc.data().createdAt.toDate === "function"
                            ? doc.data().createdAt.toDate().toLocaleString("zh-TW", {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                            })
                            : "",
                }));

                if (messagesData.length > 0) {
                    console.log("Setting oldest timestamp from initial load");
                    setOldestMessageTimestamp(
                        messagesData[messagesData.length - 1].createdAt,
                    );

                    // 更新已加载消息ID集合
                    messagesData.forEach((msg) => {
                        loadedMessageIdsRef.current.add(msg.id);
                    });
                }

                // Sort messages by timestamp (oldest first)
                const sortedMessages = [...messagesData].reverse();
                console.log("Initial messages loaded:", sortedMessages.length);
                setMessages(sortedMessages);

                // 只在初次加载时滚动到底部，并且确保只执行一次
                if (isInitialLoadRef.current) {
                    isInitialLoadRef.current = false;
                    setTimeout(() => {
                        if (messagesContainerRef.current) {
                            console.log("Scrolling to bottom on initial load");
                            messagesContainerRef.current.scrollTop =
                                messagesContainerRef.current.scrollHeight;
                        }
                    }, 100);
                }
            },
            error: (error) => {
                console.error("Error loading messages:", error);
            },
        });

        return () => unsubscribe();
    }, [chatId, firestore, setMessages]); // 移除 loadingMoreMessages 依賴，避免重新訂閱

    // 使用ref来跟踪加载状态，避免闭包问题
    const loadingMoreMessagesRef = useRef(loadingMoreMessages);
    const hasMoreMessagesRef = useRef(hasMoreMessages);

    // 更新ref值
    useEffect(() => {
        loadingMoreMessagesRef.current = loadingMoreMessages;
        hasMoreMessagesRef.current = hasMoreMessages;
    }, [loadingMoreMessages, hasMoreMessages]);

    // 只在初次加载消息时滚动到底部
    const isInitialLoadRef = useRef(true);

    // Handle scroll to load more messages
    const handleScroll = useCallback(
        async (e) => {
            if (loadingMoreMessagesRef.current || !hasMoreMessagesRef.current) return;

            const {scrollTop} = e.currentTarget;
            // 当滚动到顶部时加载更多历史消息，增加阈值避免频繁触发
            if (scrollTop < 20) {
                console.log("Loading more messages...", scrollTop);
                // 保存当前滚动位置和内容高度
                const scrollContainer = e.currentTarget;
                const oldScrollHeight = scrollContainer.scrollHeight;

                // 保存当前消息列表的副本，以便稍后比较
                const currentMessagesList = [...(messages || [])];
                console.log(
                    "Current messages count before loading more:",
                    currentMessagesList.length,
                );

                setLoadingMoreMessages(true);

                try {
                    if (!oldestMessageTimestamp) {
                        console.log("No oldest message timestamp");
                        setLoadingMoreMessages(false);
                        return;
                    }

                    console.log("Fetching messages before:", oldestMessageTimestamp);

                    const q = query(
                        collection(firestore, "chatroom-messages"),
                        where("chatroomId", "==", chatId),
                        orderBy("createdAt", "desc"),
                        startAfter(oldestMessageTimestamp),
                        limit(30),
                    );

                    const snapshot = await getDocs(q);
                    console.log("Fetched older messages:", snapshot.docs.length);

                    const olderMessages = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                        formattedTime:
                            doc.data().createdAt &&
                            typeof doc.data().createdAt.toDate === "function"
                                ? doc.data().createdAt.toDate().toLocaleString("zh-TW", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: false,
                                })
                                : "",
                    }));

                    if (olderMessages.length > 0) {
                        // 更新最旧消息的时间戳
                        setOldestMessageTimestamp(
                            olderMessages[olderMessages.length - 1].createdAt,
                        );

                        // 过滤掉已经加载过的消息
                        const uniqueOlderMessages = olderMessages.filter(
                            (msg) => !loadedMessageIdsRef.current.has(msg.id),
                        );
                        console.log("Unique older messages:", uniqueOlderMessages.length);

                        // 更新已加载消息ID集合
                        uniqueOlderMessages.forEach((msg) => {
                            loadedMessageIdsRef.current.add(msg.id);
                        });

                        const currentMessages = messages || [];
                        console.log("Previous messages:", currentMessages.length);

                        // 反转uniqueOlderMessages使其按时间正序
                        const reversedOlderMessages = [...uniqueOlderMessages].reverse();
                        console.log("New older messages:", reversedOlderMessages.length);

                        // 创建新的消息数组，将旧消息放在前面
                        const newMessages = [...reversedOlderMessages, ...currentMessages];
                        console.log("Combined messages:", newMessages.length);
                        console.log(
                            "Messages IDs in memory:",
                            loadedMessageIdsRef.current.size,
                        );

                        console.log("Setting messages state with new combined messages");
                        // 使用函數形式確保我們總是基於最新的狀態
                        setMessages((prevMessages) => {
                            // 檢查是否與當前狀態相同
                            if (prevMessages === currentMessages) {
                                console.log("Using new combined messages");
                                return newMessages;
                            } else {
                                console.log("State changed, merging with current state");
                                // 如果狀態已經改變，我們需要重新合併
                                return [...reversedOlderMessages, ...prevMessages];
                            }
                        });

                        // 简化滚动位置维护逻辑，避免无限回调
                        setTimeout(() => {
                            // 使用高度差方法
                            const newScrollHeight = scrollContainer.scrollHeight;
                            const heightDifference = newScrollHeight - oldScrollHeight;
                            scrollContainer.scrollTop = heightDifference + 100; // 增加偏移量，确保不会触发新的加载
                        }, 100);
                    }

                    setHasMoreMessages(olderMessages.length === 30);
                } catch (error) {
                    console.error("Error loading more messages:", error);
                } finally {
                    setLoadingMoreMessages(false);
                }
            }
        },
        [chatId, firestore, oldestMessageTimestamp, setMessages, messages],
    );

    return (
        <Flex
            direction="column"
            height="100%"
            position="relative"
            overflow="hidden"
            flex={1}
        >
            <Box
                flex={1}
                overflowY="auto"
                ref={messagesContainerRef}
                onScroll={handleScroll}
                p={4}
            >
                {loadingMoreMessages && (
                    <Center pt={2} pb={4}>
                        <CircularProgress size="20px" isIndeterminate/>
                    </Center>
                )}

                {hasMoreMessages && !loadingMoreMessages && (
                    <Center pt={2} pb={4} fontSize="sm" color="gray.500">
                        向上滾動加載更多訊息
                    </Center>
                )}

                {messages === null ? (
                    <Center h="100%">
                        <CircularProgress isIndeterminate/>
                    </Center>
                ) : (
                    <MessageList
                        messages={messages}
                        creator={creator}
                        messageClassName="message-item"
                        onAvatarClick={(userId) => navigate(`/users/search?id=${userId}`)}
                    />
                )}
            </Box>

            <Box px={4} pb={2}>
                {chatId && (
                    <>
                        <>
                            <MessageInput onSendMessage={() => {
                            }} chatId={chatId}/>
                        </>
                    </>
                )}
            </Box>
        </Flex>
    );
};

export default ChatDetail;
