import {
  Box,
  Center,
  CircularProgress,
  Flex,
  Text,
  VStack,
} from "@chakra-ui/react";
import ChatItem from "components/ChatItem";
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";

const MAX_LIMIT_QUERY = 25;

const ChatList = ({
  creator,
  onSelectChat,
  selectedChatId,
  firestore,
  userId,
  onChatsLoaded,
  filteredChats,
}) => {
  const [chats, setChats] = useState(null);
  const [lastChat, setLastChat] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const chatListRef = useRef(null);
  const containerRef = useRef(null);
  const isMountedRef = useRef(true);

  // 組件卸載時設置為 false
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
      console.log("ChatList 組件已卸載");
    };
  }, []);

  // 當聊天列表加載完成時，通知父組件
  useEffect(() => {
    if (chats && onChatsLoaded) {
      onChatsLoaded(chats);
    }
  }, [chats, onChatsLoaded]);

  // 用於調試的聊天列表變化監控
  useEffect(() => {
    if (chats) {
      console.log(`聊天列表已更新: ${chats.length} 個對話`);
    }
  }, [chats]);

  // 過濾有效的聊天記錄
  const filterValidChats = useCallback((chats) => {
    return chats.filter(
      (chat) => chat.lastMessage && !chat.lastMessage.cannedMessage,
    );
  }, []);

  // 去除重複的聊天記錄
  // const removeDuplicates = (chats) => {
  //   const uniqueChats = [];
  //   const chatIds = new Set();

  //   for (const chat of chats) {
  //     if (!chatIds.has(chat.id)) {
  //       chatIds.add(chat.id);
  //       uniqueChats.push(chat);
  //     }
  //   }

  //   return uniqueChats;
  // };

  // Initial load with real-time updates
  useEffect(() => {
    console.log("Loading chats...");

    setChats(null);
    setLastChat(null);
    setHasMore(true);
    setLoading(true);

    // Create query for initial chats
    let q;
    if (userId) {
      q = query(
        collection(firestore, "chatrooms"),
        where("userId", "==", userId),
        orderBy("lastMessagedAt", "desc"),
        limit(MAX_LIMIT_QUERY),
      );
    } else if (creator?.id) {
      q = query(
        collection(firestore, "chatrooms"),
        where("creatorId", "==", creator.id),
        orderBy("lastMessagedAt", "desc"),
        limit(MAX_LIMIT_QUERY),
      );
    } else {
      q = query(
        collection(firestore, "chatrooms"),
        orderBy("lastMessagedAt", "desc"),
        limit(MAX_LIMIT_QUERY),
      );
    }

    // Set up real-time listener
    const unsubscribe = onSnapshot(q, {
      next: (snapshot) => {
        // Handle initial load
        if (chats === null) {
          const rawChats = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // const validChats = filterValidChats(rawChats);
          // const uniqueChats = removeDuplicates(validChats);

          console.log(`初始載入: ${snapshot.docs.length} 個對話`);
          setChats(rawChats);
        } else {
          // Handle updates - only update changed documents
          snapshot.docChanges().forEach((change) => {
            const chatData = {
              id: change.doc.id,
              ...change.doc.data(),
            };

            // Only process valid chats with last messages
            if (chatData.lastMessage && !chatData.lastMessage.cannedMessage) {
              if (change.type === "added" || change.type === "modified") {
                setChats((prevChats) => {
                  // Check if this chat already exists
                  const existingIndex = prevChats.findIndex(
                    (c) => c.id === chatData.id,
                  );

                  if (existingIndex >= 0) {
                    // Update existing chat
                    const updatedChats = [...prevChats];
                    updatedChats[existingIndex] = chatData;

                    // Sort by lastMessagedAt to maintain order
                    return updatedChats.sort(
                      (a, b) =>
                        (b.lastMessagedAt?.toDate?.() || 0) -
                        (a.lastMessagedAt?.toDate?.() || 0),
                    );
                  } else {
                    // Add new chat at the beginning (it's likely the most recent)
                    return [chatData, ...prevChats].sort(
                      (a, b) =>
                        (b.lastMessagedAt?.toDate?.() || 0) -
                        (a.lastMessagedAt?.toDate?.() || 0),
                    );
                  }
                });
              } else if (change.type === "removed") {
                setChats((prevChats) =>
                  prevChats.filter((chat) => chat.id !== chatData.id),
                );
              }
            }
          });
        }

        if (snapshot.docs.length > 0) {
          setLastChat(snapshot.docs[snapshot.docs.length - 1]);
        }

        setHasMore(snapshot.docs.length >= 50);
        setLoading(false);
      },
      error: (error) => {
        console.error("Error loading chats:", error);
        setLoading(false);
      },
    });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [creator?.id, userId, firestore, filterValidChats]);

  // Function to load more chats (pagination)
  const loadMoreChats = useCallback(async () => {
    if (loading || !hasMore || !lastChat) {
      return;
    }

    setLoading(true);

    try {
      let q;
      if (userId) {
        q = query(
          collection(firestore, "chatrooms"),
          where("userId", "==", userId),
          orderBy("lastMessagedAt", "desc"),
          startAfter(lastChat),
          limit(MAX_LIMIT_QUERY),
        );
      } else if (creator?.id) {
        q = query(
          collection(firestore, "chatrooms"),
          where("creatorId", "==", creator.id),
          orderBy("lastMessagedAt", "desc"),
          startAfter(lastChat),
          limit(MAX_LIMIT_QUERY),
        );
      } else {
        q = query(
          collection(firestore, "chatrooms"),
          orderBy("lastMessagedAt", "desc"),
          startAfter(lastChat),
          limit(MAX_LIMIT_QUERY),
        );
      }

      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        setHasMore(false);
        return;
      }

      // 檢查是否與上一個 lastChat 相同
      const newLastChat = snapshot.docs[snapshot.docs.length - 1];
      if (lastChat.id === newLastChat.id) {
        setHasMore(false);
        return;
      }

      const rawChats = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const validChats = filterValidChats(rawChats);

      if (validChats.length > 0) {
        setChats((prev) => {
          // 去重
          const prevIds = new Set(prev ? prev.map((chat) => chat.id) : []);
          const uniqueNewChats = validChats.filter(
            (chat) => !prevIds.has(chat.id),
          );

          if (uniqueNewChats.length === 0) {
            return prev || [];
          }

          return [...(prev || []), ...uniqueNewChats];
        });
      }

      setLastChat(newLastChat);
      setHasMore(snapshot.docs.length >= MAX_LIMIT_QUERY);
    } catch (error) {
      console.error("Error loading more chats:", error);
    } finally {
      setLoading(false);
    }
  }, [
    creator?.id,
    userId,
    firestore,
    lastChat,
    loading,
    hasMore,
    filterValidChats,
  ]);

  // 監聽滾動事件來加載更多聊天
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    let scrollTimeout = null;

    const handleScroll = () => {
      if (scrollTimeout) clearTimeout(scrollTimeout);

      scrollTimeout = setTimeout(() => {
        if (!isMountedRef.current || loading || !hasMore) return;

        const { scrollTop, scrollHeight, clientHeight } = container;
        const isNearBottom = scrollHeight - scrollTop - clientHeight < 200;

        if (isNearBottom) {
          loadMoreChats();
        }
      }, 200);
    };

    // 如果內容不足以填滿容器，自動加載更多
    const checkInitialContent = () => {
      if (!isMountedRef.current || loading || !hasMore) return;

      const { scrollHeight, clientHeight } = container;
      if (scrollHeight <= clientHeight && chats?.length > 0) {
        loadMoreChats();
      }
    };

    // 初始檢查
    if (chats?.length > 0) {
      checkInitialContent();
    }

    // 監聽滾動事件
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
      if (scrollTimeout) clearTimeout(scrollTimeout);
    };
  }, [loadMoreChats, loading, hasMore, chats]);

  return (
    <Flex height="100%" width="100%" direction="column">
      <VStack
        ref={containerRef}
        align="stretch"
        height="100%"
        width="100%"
        overflowY="auto"
        spacing={2}
        p={2}
        className="chat-list-container"
      >
        {filteredChats ? (
          filteredChats.length === 0 ? (
            <Center mt={6}>
              <Text>沒有找到聊天室</Text>
            </Center>
          ) : (
            <>
              {filteredChats.map((chat, index) => (
                <Box key={`${chat.id}-${index}`}>
                  {chat.creatorDisplayName && (
                    <Text fontSize="xs" color="gray.500" ml={2} mb={1}>
                      創作者: {chat.creatorDisplayName}
                    </Text>
                  )}
                  <ChatItem
                    chat={chat}
                    creator={creator}
                    isSelected={chat.id === selectedChatId}
                    onClick={() => onSelectChat(chat.id, chat)}
                  />
                </Box>
              ))}
            </>
          )
        ) : chats === null ? (
          <Center mt={6}>
            <CircularProgress isIndeterminate />
          </Center>
        ) : (
          <>
            {chats.map((chat, index) => (
              <Box key={`${chat.id}-${index}`}>
                {chat.creatorDisplayName && (
                  <Text fontSize="xs" color="gray.500" ml={2} mb={1}>
                    創作者: {chat.creatorDisplayName}
                  </Text>
                )}
                <ChatItem
                  key={`${chat.id}-${index}`}
                  chat={chat}
                  creator={creator}
                  isSelected={chat.id === selectedChatId}
                  onClick={() => onSelectChat(chat.id, chat)}
                />
              </Box>
            ))}
            <Box
              ref={chatListRef}
              h="20px"
              py={2}
              textAlign="center"
              fontSize="sm"
              color="gray.500"
            >
              {loading ? (
                <Center mt={2} mb={4}>
                  <CircularProgress size="20px" isIndeterminate />
                </Center>
              ) : hasMore ? (
                "滾動加載更多"
              ) : chats.length > 0 ? (
                "已加載全部對話"
              ) : (
                "沒有對話記錄"
              )}
            </Box>
          </>
        )}
      </VStack>
    </Flex>
  );
};

export default ChatList;
