import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    useBreakpointValue,
    useDisclosure,
} from "@chakra-ui/react";
import {useCallback, useState} from "react";
import {useFirebase} from "context/firebase.context";
import ChatList from "./chat/ChatList";
import ChatDetail from "./chat/ChatDetail";

export const ChatsList = ({creator, userId}) => {
    const [selectedChat, setSelectedChat] = useState(null);
    const [selectedChatData, setSelectedChatData] = useState(null);
    const [messages, setMessages] = useState(null);
    const [apiResponse, setApiResponse] = useState(null);
    const {firestore} = useFirebase();

    // Mobile drawer control
    const {isOpen, onOpen, onClose} = useDisclosure();
    const isMobile = useBreakpointValue({base: true, md: false});

    // Handle chat selection
    const handleSelectChat = useCallback(
        (chatId, chatData) => {
            // 如果已经选中，不重复加载
            if (selectedChat === chatId) {
                // 在移动端，只需打开抽屉
                if (isMobile) {
                    onOpen();
                }
                return;
            }

            setSelectedChat(chatId);
            setSelectedChatData(chatData);
            setMessages(null);
            setApiResponse(null);

            // On mobile, open the drawer when selecting a chat
            if (isMobile) {
                onOpen();
            }

            // Reset API response when changing chats
            setApiResponse(null);
        },
        [isMobile, onOpen, selectedChat],
    );

    // Handle closing chat detail
    const handleCloseChat = useCallback(() => {
        setSelectedChat(null);
        setSelectedChatData(null);
        setMessages(null);

        // On mobile, close the drawer
        if (isMobile) {
            onClose();
        }
    }, [isMobile, onClose]);

    return (
        <Flex direction={{base: "column", md: "row"}} overflow="hidden">
            {/* Chat list - always visible on desktop, hidden on mobile when drawer is open */}
            <Box
                w={{base: "100%", md: "350px"}}
                h={{base: "100%", md: "100%"}}
                borderRight={{md: "1px solid"}}
                borderColor={{md: "gray.700"}}
                display={{base: isOpen && isMobile ? "none" : "flex", md: "flex"}}
                overflow="hidden"
                height="100%"
            >
                <ChatList
                    creator={creator}
                    onSelectChat={handleSelectChat}
                    selectedChatId={selectedChat}
                    firestore={firestore}
                    userId={userId}
                />
            </Box>

            {/* Chat detail - visible on desktop, in drawer on mobile */}
            {isMobile ? (
                <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
                    <DrawerOverlay/>
                    <DrawerContent bg="gray.800">
                        <DrawerHeader borderBottomWidth="1px" borderColor="gray.700">
                            {selectedChatData?.displayName || "聊天記錄"}
                        </DrawerHeader>
                        <DrawerCloseButton/>
                        <DrawerBody p={0}>
                            {selectedChat && (
                                <ChatDetail
                                    chatId={selectedChat}
                                    creator={creator}
                                    apiResponse={apiResponse}
                                    setApiResponse={setApiResponse}
                                    firestore={firestore}
                                    onClose={handleCloseChat}
                                    messages={messages}
                                    setMessages={setMessages}
                                />
                            )}
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            ) : (
                <Box
                    flex={1}
                    display={selectedChat ? "flex" : "none"}
                >
                    {selectedChat && (
                        <ChatDetail
                            chatId={selectedChat}
                            creator={creator}
                            apiResponse={apiResponse}
                            setApiResponse={setApiResponse}
                            firestore={firestore}
                            onClose={handleCloseChat}
                            messages={messages}
                            setMessages={setMessages}
                        />
                    )}
                </Box>
            )}
        </Flex>
    );
};
